<template>
  <div class="productDescription">
    <img src="https://ziyouzhaofang-bucket1.oss-cn-beijing.aliyuncs.com/JSMp4/project_1.png" alt="1">
    <img src="https://ziyouzhaofang-bucket1.oss-cn-beijing.aliyuncs.com/JSMp4/project_2.png" alt="2">
    <img src="https://ziyouzhaofang-bucket1.oss-cn-beijing.aliyuncs.com/JSMp4/project_3.png" alt="3">
    <img src="https://ziyouzhaofang-bucket1.oss-cn-beijing.aliyuncs.com/JSMp4/project_4.png" alt="4">
  </div>
</template>

<script>
export default {
  name: "productDescription"
}
</script>

<style scoped lang="scss">
.productDescription {
  margin-top: 72px;
  font-size: 0;

  img {
    width: 100%;

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      margin-top: 160px;
    }
  }
}
</style>
